body{
    overflow: hidden;
}
.logout-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    transition: transform 0.2s;
  }
  
  .logout-button.clicked {
    transform: scale(0.95);
  }
  .fixed-width-span {
    width: 150px;
    display: inline-block;
    color:red;
    margin-bottom: 1vh;
    /* Add any other necessary styles */
  }
 .hidden{
    visibility: hidden;
 }  
 .disabled-menu-item{
   display: none;
 }
 .Mui-even {
  background-color: aliceblue; /* or any other color for even rows */
}

.Mui-odd {
  background-color: white; /* or any other color for odd rows */
}
a{
  color: seagreen;
  text-decoration: underline;
  text-underline-offset: 2px;
  cursor:pointer;
}
